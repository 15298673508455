/**
 * Reset some basic elements
 */
blockquote,
body,
dd,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
ol,
p,
pre,
ul {
    margin: 0;
    padding: 0;
}
/**
 * Basic styling
 */
body {
    font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
    color: $text-color;
    background-color: $background-color;
}
/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
%vertical-rhythm,
blockquote,
dl,
figure,
h1,
h2,
h3,
h4,
h5,
h6,
ol,
p,
pre,
table,
ul {
    margin-bottom: $spacing-unit / 2;
}
.thingy-centered {
    text-align:center;
    width:100%;
}
/**
 * Images
 */
img {
    max-width: 100%;
    vertical-align: middle;
}
/**
 * Figures
 */
figure > img {
    display: block;
}

.fig-container {
  text-align: center;
}

.pixelated {
  image-rendering: pixelated;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  img.figure {
      max-width: 100%
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  img.figure {
      max-width: 65%
  }
  div.caption {
    margin-right: 17.5%
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  img.figure {
      max-width: 65%
  }
  div.caption {
    margin-right: 17.5%
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  img.figure {
      max-width: 65%
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  img.figure {
      max-width: 65%
  }
  div.caption {
    margin-right: 17.5%;
    margin-left: 17.5%;
  }
}

div.caption {
    font-size: $small-font-size;
}
/**
 * Lists
 */
ol,
ul {
    margin-left: $spacing-unit;
}

li {
    > ol,
    > ul {
        margin-bottom: 0;
    }
}
/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: $base-font-weight;
}
/**
 * Links
 */
a {
    color: $link-color;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}
/**
 * Grayed list items
 */
.grayed {
    color: gray;
    text-decoration: none;
}
/**
 * Blockquotes
 */
blockquote {
    color: $grey-color;
    padding-left: $spacing-unit / 2;
    border-left: 2px solid $grey-color-light;
    font-style: italic;
    font-size: $small-font-size;

    > :last-child {
        margin-bottom: 0;
    }
}
/**
 * Code formatting
 */
code,
pre {
    background-color: #fcfcfc;
}

code {
    padding: 1px 5px;
    font-family: $code-font-family;
    color: #f14e32;
}

pre {
    padding: 8px 12px;
    overflow-x: auto;
    border-left: 2px solid $grey-color-light;

    > code {
        border: 0;
        padding-right: 0;
        padding-left: 0;
        tab-size: 4;
        color: inherit;
    }
}
/**
 * Table
 */
table {
    width: 100%;
    max-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
}

td,
th {
    padding: 0.5rem;
    line-height: inherit;
}

th {
    text-align: left;
    vertical-align: bottom;
    border-bottom: 2px solid $grey-color-light;
}

td {
    vertical-align: top;
    border-bottom: 1px solid $grey-color-light;
}
/**
 * Horizontal rule
 */
hr {
    border: none;
    border-top: 1px solid lighten($grey-color, 30%);
    margin: $spacing-unit auto;
}
/**
 * Clearfix
 */
%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

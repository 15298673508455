@charset "utf-8";

// Our variables
$base-font-family: 'Droid Sans', sans-serif;
$code-font-family: Inconsolata, Monaco, Consolas, monospace;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  0.875rem;
$base-line-height: 1.5;

$spacing-unit:     2rem;

$text-color:       #111;
$background-color: #fff;
$link-color:       #0645ad;

$grey-color:       #aaa;
$grey-color-light: lighten($grey-color, 25%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$on-palm:          600px;
$on-:        800px;

bio {
  display: inline-block;
}

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
        bio {
          display: block;
        }
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
		url(https://fonts.googleapis.com/css?family=Droid+Sans),
        "base",
        "layout",
        "syntax-highlighting"
;
